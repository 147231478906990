html, body, address, article, aside, footer, header, h1, h2, h3, h4, h5, h6, main, nav, section, blockquote, dd, div, dl, dt, figcaption, figure, hr, li, ol, p, pre, ul, a, abbr, b, bdi, bdo, br, cite, code, data, dfn, em, i, kbd, mark, q, rb, rp, rt, rtc, ruby, s, samp, small, span, strong, sub, sup, time, u, var, wbr, area, audio, img, map, track, video, embed, iframe, object, param, picture, source, canvas, noscript, script, del, ins, caption, col, colgroup, table, tbody, td, tfoot, th, thead, tr, button, datalist, fieldset, form, input, label, legend, meter, optgroup, option, output, progress, select, textarea, details, dialog, menu, menuitem, summary {
  all: unset;
  display: revert;
}

body {
  padding: 3rem;
  font-family: monospace;
  line-height: 1.5;
}

a, button {
  cursor: pointer;
}

:is(input, button):focus-visible {
  outline: 1px dotted #000;
}

.menu {
  gap: 1.5rem;
  display: flex;
}

.button {
  padding-inline: .5rem;
}

.check-box {
  cursor: pointer;
  gap: .25rem;
  display: flex;
}

.check-box input:after {
  content: "☐";
  display: inline;
}

.check-box input:checked:after {
  content: "☑";
}

.number-input {
  border-bottom: 1px solid;
  width: 6ch;
}

.color-table {
  grid-template-columns: .3fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  margin-block-start: 1.5em;
  display: grid;
}

.color-table-cell {
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  min-width: 10rem;
  padding: 1em;
  display: flex;
}
/*# sourceMappingURL=index.af8989f6.css.map */
