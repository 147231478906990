html,
body,
address,
article,
aside,
footer,
header,
h1,
h2,
h3,
h4,
h5,
h6,
main,
nav,
section,
blockquote,
dd,
div,
dl,
dt,
figcaption,
figure,
hr,
li,
ol,
p,
pre,
ul,
a,
abbr,
b,
bdi,
bdo,
br,
cite,
code,
data,
dfn,
em,
i,
kbd,
mark,
q,
rb,
rp,
rt,
rtc,
ruby,
s,
samp,
small,
span,
strong,
sub,
sup,
time,
u,
var,
wbr,
area,
audio,
img,
map,
track,
video,
embed,
iframe,
object,
param,
picture,
source,
canvas,
noscript,
script,
del,
ins,
caption,
col,
colgroup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
datalist,
fieldset,
form,
input,
label,
legend,
meter,
optgroup,
option,
output,
progress,
select,
textarea,
details,
dialog,
menu,
menuitem,
summary {
  all: unset;
  display: revert;
}

body {
  line-height: 1.5;
  font-family: monospace;
  padding: 3rem;
}

a,
button {
  cursor: pointer;
}

:is(input, button):focus-visible {
  outline: 1px dotted black;
}

.menu {
  display: flex;
  gap: 1.5rem;
}

.button {
  padding-inline: 0.5rem;
}

.check-box {
  cursor: pointer;
  display: flex;
  gap: 0.25rem;
}

.check-box input::after {
  content: "☐";
  display: inline;
}

.check-box input:checked::after {
  content: "☑";
}

.number-input {
  border-bottom: 1px solid currentColor;
  width: 6ch;
}

.color-table {
  display: grid;
  grid-template-columns: 0.3fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  margin-block-start: 1.5em;
}

.color-table-cell {
  align-items: center;
  display: flex;
  gap: 1em;
  justify-content: space-between;
  min-width: 10rem;
  padding: 1em;
}
